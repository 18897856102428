/* COLORS */
@LightGray: #F6F6F6;
@Gray: #A3A3A3;
@DarkGray: #7D7E81;


@BrandTeal: #61C1A4;
@BrandBlue: #2E58A6;
@BrandDarkBlue: #2E58A6;
@BrandGold: #C3A72A;

.duration-minutes-button {
  background-color: @BrandDarkBlue !important;
  color: #ffffff !important;
}

.absent-date-row {
  background-color: lighten(red, 25%) !important;
}

.absent-date-button {
  background-color: lighten(red, 25%) !important;
  color: @gray;
  &:hover {
    color: @gray;
  }
}

.restricted-date-row {
  background-color: lighten(@BrandGold, 40%) !important;
}


.restricted-date-button {
  background-color: lighten(@BrandGold, 40%) !important;
  color: @gray;
  &:hover {
    color: @gray;
  }
}

/*.nav-row {*/
  /*.col-lg-12 {*/
    /*padding-left: 0px;*/
    /*padding-right: 0px;*/
  /*}*/
/*}*/

/*.navbar {*/
  /*.navbar-brand img {*/
    /*height: 100%*/
  /*}*/
  /*font-size: 14px;*/
  /*font-weight: 200;*/
/*}*/

.add-pad {
  margin-top: -4px;
}

.error-container {
  margin-top: 250px;
}

.error-title {
  font-size: 50px;
  color: @DarkGray;
}

#add-time-study {
  text-align: right;
}

.body-font {
  font-family: 'Raleway', sans-serif;
}

.header-font {
  font-family: 'Lora', serif;
}

.bottom {
  text-align: center;
}

.th > .sorting_desc, .sorting_asc, .sorting {
  cursor: pointer;
}

.list-font {
  font-family: 'Raleway', sans-serif;
}

.edit-text {
  color: @BrandBlue;
  cursor: pointer;
}

.edit-text:hover {
  color: @BrandDarkBlue;
}

/*form input[type=text], input[type=number], input[type=email], input[type=date], input[type=password], #password, select {*/
  /*border: 3px solid @LightGray;*/
  /*margin-top: -7px;*/
  /*margin-bottom: 7px;*/
/*}*/

/*.input-group {*/
  /*margin-top: -7px;*/
  /*margin-bottom: 7px;*/
  /*input[type=text], input[type=number], input[type=email], input[type=date], input[type=password], #password, select {*/
    /*margin: 0;*/
  /*}*/
  /*.input-group-addon {*/
    /*border: 3px solid @LightGray;*/
  /*}*/
  /*.input-group-addon:first-child {*/
    /*border-right: 0;*/
  /*}*/
  /*.input-group-addon:last-child {*/
    /*border-left: 0;*/
  /*}*/
/*}*/

.login-label {
  background: @LightGray;
  padding: 10px;
  margin-top: 25px;
  margin-bottom: 10px;
  color: @BrandBlue;
  display: inline-block
}

.login-label-container {
  text-align: center;
}

.ibox div {
  border: none black;
  background: @LightGray;
}

.panel-footer {
  background: white;
}

/*hr {*/
  /*margin-top: 11px;*/
  /*margin-bottom: 0;*/
  /*border-top: 1px solid black;*/
/*}*/


.center-vertical {
  vertical-align: middle;
}

.header-margin {
  margin-left: 10px;
}

.checkbox-padding {
  padding: 10px;
  cursor: pointer;
}

#login-logo {
  margin: auto;
}

#login-container {
  @media (max-width: 767px) {
    margin-top: 100px;
  }
  @media (min-width: 768px) {
    margin-top: 200px;
  }
}

.header-arrow {
  color: white;
}

.header-arrow:hover {
  color: @Gray;
}

/* TEXT */
textarea {
  width: 100%;
  resize: none;
}

.list-container {
  padding-top: 10px;
}

.list-header-font {
  font-weight: 100;
  font-size: small;
}

.label-font {
  font-weight: 100;
  font-size: small;
}

a.link-no-hover:hover {
  color: black !important;
  background: transparent !important;
  cursor: default !important;
}

/* BUTTONS */
.button-mini {
  color: #ffffff;
  border-radius: 25px;
  background: @BrandTeal;
  padding: 5px 5px 5px 5px;
  text-decoration: none;
  border: none !important;
}

.button-mini:hover {
  background: @BrandDarkBlue;
  text-decoration: none;
}

/*.button {*/
  /*color: #ffffff;*/
  /*border-radius: 25px;*/
  /*font-family: 'Raleway', serif;*/
  /*background: @BrandTeal;*/
  /*padding: 10px 20px 10px 20px;*/
  /*text-decoration: none;*/
  /*border: none !important;*/
/*}*/

/*.button:hover {*/
  /*background: @BrandDarkBlue;*/
  /*text-decoration: none;*/
/*}*/

.add-class {
  padding-left: 25px;
}

#class-list {
  padding-top: 20px;
}

.header-margin {
  color: black;
}

.item-add {
  cursor: pointer;
}

.item-add:hover img {
  opacity: 0.8;
}

.submit {
  margin-top: 20px;
}

.bulk {
  margin-right: 28px;
}

.checkbox-padding:hover {
  color: white;
  background: @Gray;
}


/*.nav-tabs > li, .nav-pills > li {*/
  /*float:none;*/
  /*display:inline-block;*/
  /**display:inline; [> ie7 fix <]*/
  /*zoom:1; [> hasLayout ie7 trigger <]*/
/*}*/

/*.nav-tabs, .nav-pills {*/
  /*text-align:right;*/
/*}*/

.uppercase {
  text-transform: uppercase;
}

.add-item {
  cursor: pointer;
}

.add-item:hover img {
  opacity: 0.8;
}


/*[> search <]*/
.table-list-search tbody tr {
  cursor: pointer;
}

.search-row {
  margin-top: 10px;
}

/*.table tbody a {*/
  /*color: #676a6c;*/
/*}*/

/*.table tr:hover a {*/
  /*color: white;*/
/*}*/

/*.table tbody tr:hover {*/
  /*background-color: @DarkGray;*/
  /*color: white;*/
/*}*/

.add-column {
  &:extend(.col-md-3);
}

.search-column {
  &:extend(.col-md-3);
}

.search {
  position: relative;
}

.search input {
  padding-left: 30px;
  border: 0;
  outline: 0;
  background: transparent;
  border-bottom: 2px solid black;
}

.search i {
  position: absolute;
  padding: 10px 12px;
  pointer-events: none;
}

.cursor-pointer {
  cursor: pointer;
}

.paginate_button {
  margin-left: 2.5px;
  margin-right: 2.5px;
}

.app-inline-block {
  display: inline-block;
}

/*#user-menu-dropdown {*/
  /*position: absolute;*/
  /*top: 60px;*/
/*}*/

.position-relative {
  position: relative;;
}

.loading-container {
  background-image: url(~"../../img/spinner.gif");
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: rgba(0,0,0,0.2);
  z-index: 1;
}