.student-form {
  .absent-text {
    color: #c00018;
  }
  /* The switch - the box around the slider */
  .switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 12px;
    margin-bottom: 8px;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: -3px;
    bottom: -4px;
    background-color: #e8e8e8;
    -webkit-transition: .4s;
    transition: .4s;
    box-shadow: 0px 1px 8px -1px rgba(0,0,0,.8);
  }

  input:checked + .slider {
    background-color: #bfcce3;
  }

  input:checked + .slider:before {
    background-color: #2f58a6;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .switch-label {
    display: inline-block;
    margin-right: 10px;
    width: 80%;
    font-size: 16px;
    > label {
      margin-bottom: 0px;
    }
  }
  .switch-container {
    display: inline-block;
    width: 15%;
  }
  .switch-well-container {
    width: 365px;
  }
  .text-small {
    font-size: 10px;
  }
  .modal-content {
    border-radius: 0px;
    padding: 16px;
  }
  .modal-header {
    border-bottom: 0px;
  }
  .p-b {
    padding-bottom: 10px;
  }
  .modal-footer {
    border-top: 0px;
  }
  .modal-md {
    width: 400px;
  }
}