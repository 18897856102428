/* Common */
@import (less) "bootstrap/less/bootstrap.less";
@import (less) "bootstrap-switch/src/less/bootstrap3/bootstrap-switch";
@import (less) "font-awesome/less/font-awesome.less";

/* Variables */
@import "//fonts.googleapis.com/css?family=Roboto:400,300italic|Open+Sans:400,300,700";
@fa-font-path:   "../fonts";
@import (less) "_variables.less";

/* App Specific */
@import (less) "app.less";
/*@import (less) "theme.less";*/
@import (less) "_navbar.less";
@import (less) "_student-form.less";


@media (min-width: @screen-sm-min) {
  @import (less) "app.sm.less";
}
@media (min-width: @screen-md-min) {
  @import (less) "app.md.less";
}
@media (min-width: @screen-lg-min) {
  @import (less) "app.lg.less";
}

/*@import (less) "blockgrid.less";*/
