.top-nav-container {
  padding-left: 0px;
  padding-right: 0px;
}

.navbar-static-top {
  background-color: @BrandBlue;
}

.main-nav-item,
.main-nav-item > a {
  color: #ffffff !important;
}

.main-nav-item.active > a  {
  color: @BrandGold !important;
}

.main-nav-item > a:hover,
.main-nav-item > a:focus {
  background-color: @BrandBlue !important;
  color: @BrandGold !important;
}
